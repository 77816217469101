import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WebbDividerSmall from "../webx/webb-divider-sm";
import {
  DefaultFormatsList,
  FormatsCreate,
} from "../../services/srvc-formats-realm";
import FormatsModalModule from "../formats/formats-modal";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { GetUserForm } from "../../services/srvc-utilities";

export default function TeamTransferDefaultFormatsModule() {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [memo, setMemo] = useState("");
  const [memoType, setMemoType] = useState("");

  const handleShowModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleSubmit = async () => {
    // setLoader(true);
    setMemo("Please wait...");
    if (!selectedItem) {
      setMemo("Please select a format.");
      // setLoader(false);
      return alert("Please select a format.");
    }

    const datx = {
      name: selectedItem?.name,
      memo: selectedItem?.memo,
      vars: selectedItem?.vars || [], // Adjust depending on data structure
      valid: "24",
      role: "user",
      domain: "work",
      consumer: asset.item,
      bs: selectedItem?.bs || "",
    };

    try {
      const result = await FormatsCreate({ data: datx, srvc: "******" });
      if (result.stat) {
        setMemo("Format Created Successfully");
        // console.log("Format Created");
        setMemoType("success");
      } else {
        setMemo("Format Creation Failed");
        setMemoType("danger");
      }
    } catch (error) {
      console.error("Error creating format:", error);
      setMemo("Error occurred");
      setMemoType("danger");
    } finally {
      // setLoader(false);
      //  handleCloseModal();
    }
  };

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await DefaultFormatsList({
          data: {
            consumer: asset.item,
            sort:'',
            index: 1,
            items: 25,
            filters: { name: {} },
          },
        });

        setData(result.data.list);
        setLoader(false);
      };
      fetchData();
    }
  }, []);

  if (loader)
    return (
      <>
        <p>Loading ......</p>
      </>
    );

  return (
    <>
      <div>
        <div className="row row-cols-1 row-cols-md-2 g-2">
          {data &&
            data.map((item, i) => (
              <div className="col" key={i}>
                <div
                  className="d-flex flex-column justify-content-between p-3 rounded-xd border border-black h-100"
                  style={{ boxShadow: "0 6px 6px rgba(0,0,0,0.1)" }}
                >
                  <div>
                    <p className="text-bold m-0">{item.name}</p>
                    <WebbDividerSmall />
                    <p
                      className="text-small m-0 text-wd text-ellipsis"
                      style={{ height: "40px" }}
                    >
                      {item.memo}
                    </p>
                  </div>
                  {i == 0 && (
                    <div className="mt-3 text-small">
                      <p className="text-bold">Data to be submitted by user:</p>
                      <ul>
                        <li>
                          <strong>Name</strong> - So we can personalize your
                          experience
                        </li>
                        <li>
                          <strong>Email</strong> - For important updates and
                          notifications
                        </li>
                        <li>
                          <strong>Mobile</strong> - For quick and easy
                          communication
                        </li>
                      </ul>
                    </div>
                  )}
                  {i == 1 && (
                    <div className="mt-3 text-small">
                      <p className="text-bold">Data to be submitted by user:</p>
                      <ul>
                        <li>
                          <strong>Name</strong> - Helps us address you
                          personally
                        </li>
                        <li>
                          <strong>Mobile</strong> - For quick and effective
                          communication
                        </li>
                        <li>
                          <strong>Identity Proof</strong> - Please provide any
                          one of the following:
                          <ul className="mt-1">
                            <li>
                              <strong>Voter ID</strong>
                            </li>
                            <li>
                              <strong>Driver's License</strong>
                            </li>
                            <li>
                              <strong>Aadhaar</strong>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* Buttons at the bottom */}
                  <div className="d-flex justify-content-between mt-auto pt-2">
                    <button
                      className="btn btn-light border back-color-wite rounded-xx text-small"
                      type="button"
                      disabled={true}
                    >
                      Details
                    </button>

                    <button
                      className="btn btn-primary border-none rounded-xx text-small text-nowrap px-1"
                      type="button"
                      disabled={false}
                      onClick={() => handleShowModal(item)}
                    >
                      Add to Formats
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Modal Component for Confirmation */}
      {showModal && (
        <FormatsModalModule
          show={showModal}
          action={SampleActionComponent}
          actionProps={{ item: selectedItem }}
          memo={memo}
          loading={loader}
          onHide={handleCloseModal}
          onConfirm={handleSubmit}
        />
      )}
    </>
  );
}

// Sample Action Component for Modal (you can customize this further)
const SampleActionComponent = ({ item }) => (
  <div>
    <h4>{item?.name}</h4>
    <p>{item?.memo}</p>
  </div>
);
