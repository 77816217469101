import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'

import { FormatsList } from '../../services/srvc-formats-realm'

import { getDate } from '../../utils/utils'
import Table from '../common/Table'

import { DispalyLoder, StatusBadge } from '../common/utils'

const list = [
  {status: '0', name: 'Created', color: 'text-color-tint'},
  {status: '1', name: 'In Progress', color: 'text-color-wait'},
  {status: '2', name: 'In Progress', color: 'text-color-wait'},
  {status: '3', name: '******', color: 'text-color-wait'},
  {status: '4', name: 'Cancelled', color: 'text-color-error'},
  {status: '5', name: '******', color: 'text-color-wait'},
  {status: '6', name: 'Success', color: 'text-color-success'},
  {status: '7', name: 'Declined (Uer)', color: 'text-color-error'},
  {status: '8', name: 'Revoked (User)', color: 'text-color-error'},
  {status: '9', name: 'Cancelled (User)', color: 'text-color-error'}
]

export default function FormatsListConsumerModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()
  
  const [loader, setLoader] = useState(true)
  
  const [data, setData] = useState()
  const [sort, setSort] = useState()

  // const header = ['name', 'memo', 'domain', 'created', 'status', 'view']
  const header = ['name', 'memo', 'created', 'status', 'view']

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const res = await FormatsList({
          data: {
            consumer: asset.item,
            index: 1,
            items: 25,
            filters: { name: {} }
          }
        })
        // console.log(res.data)
        const temp = res.data?.list?.sort((a, b) => b.created - a.created)
        setData(temp)
        setLoader(false)
      }
      fetchData()
    }
  }, [])

  if (loader) return <>Please Wait...</>
  if (!loader && (!data || data.length === 0))
  return <>No Format Listed</>

  return <>
    <div className='mt-3'>
    <div className='border-bottom'></div>
    {data && data.map((item, x) => (
      <div className='' key={x}>
      <div className='d-flex justify-content-between mt-3 mb-3' >
        <div className='me-2'>
          <span className={`align-middle text-small ${item.feat.live ? 'text-color-success' : 'text-color-tone'}`}>
            <i className='bx bxs-circle'></i>
          </span>
        </div>

        <div className='' style={{width: '66%'}}>
          <p className='text-bold m-0'>{item.name || '******'}</p>
          <p className='m-0'>{item.memo || '******'}</p>
          <p className='m-0'>{'Data Points: '}{item.vars.length || '0'}</p>
          <p className='text-small m-0 d-none'>{'Creted: '}{((new Date(item.created)).toLocaleString()) || '******'}</p>
        </div>

        <div className='text-end' style={{width: ''}}>
          <a href={`https://minx.nuvo.bi/dm/${item.item}`} target='_blank'>Launch</a>
        </div>

        <div className='ms-auto text-end'>
          <Link to={`/team/formats/${item.item}`}>View</Link>
          {/* <p className='m-0' onClick={() => handleClick(item.item)}>View</p> */}
        </div>
      </div>
      <div className='border-bottom'></div>
      </div>
    ))}
    </div>
  </>
  
}
