import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import FileCreateCX from '../../services/srvc-filecreate-cweb-xx'
import { FormatsList } from '../../services/srvc-formats-realm'
import { TransfersDataCreate } from '../../services/srvc-transfers-data-realm'
import { GetUserForm } from '../../services/srvc-utilities'
import FormNeeded from '../webx/form-needed'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import { Toast } from '../common/utils'
import { getQuery } from '../../utils/utils'

export default function TransfersCreateModule () {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [form, setForm] = useState(false)
  const [memo, setMemo] = useState('')
  const [memoType, setMemoType] = useState('')
  const [media, setMedia] = useState({})
  const [format, setFormat] = useState([])
  const [data, setData] = useState({
    name: '',
    memo: '',
    format: getQuery('format'),
    sort: '',
    start: '',
    end: ''
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const result = await FormatsList({
          data: {
            consumer: asset.item
          }
        })
        if (result.stat) {
          setFormat(result.data.list)
        }
        setLoader(false)
      }
      fetchData()
    }
  }, [])

  useEffect(() => {
    const selected = format.find(item => item.item === data.format)
    setData({ ...data, memo: selected?.memo || '' })
  }, [data.format, format])

  const handleSubmit = async () => {
    setLoader(true)
    setSubmit(true)
    setMemo('Please wait...')
    setMemoType('info')
    if (!data.format) {
      alert('Please select format')
      setLoader(false)
      setSubmit(false)
      setMemo('')
      return
    }
    const datx = {
      user: {
        mail: data.user
      },
      format: data.format,
      memo: data.memo,
      consumer: asset.item,
      sale: { number: '1', ticker: '******' },
      rate: { number: '0', ticker: '******' }
    }
    // console.log(datx)
    const result = await TransfersDataCreate({ data: datx, srvc: '******' })
    // console.log(result)
    if (result.stat) {
      setDone(true)
      setMemo('Transfer Created')
      setMemoType('success')
    } else {
      setDone(false)
      setMemo(`Transfer Creation Failed: ${result.memo}`)
      setMemoType('danger')
    }
    setLoader(false)
  }

  const handleMediaBanner = async filedata => {
    // console.log(filedata)
    setMedia({ link: filedata?.file.link, mime: filedata?.file?.mime })
  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }
  return (
    <>
      {/* info */}
      <div className='mx-3'>
        <h2 className='text-normal m-0'>{'Create Transfer'}</h2>

        <WebbDividerSmall />
      </div>

      <div
        className={
          submit
            ? 'back-color-wite p-3 rounded-xd'
            : 'back-color-wite p-3 rounded-xd'
        }
      >
        <div className={''}>
          <p className='text-normal m-0'>1. Enter Details</p>
          <WebbDividerSmall />
          <div className='mb-3'>
            <label className='form-label text-small'>
              Select Format <FormNeeded />
            </label>
            <select
              className='form-select'
              name='format'
              onChange={e => handleChange('format', e.target.value)}
              value={data?.format}
            >
              <option value={''} key={0}>
                ---
              </option>
              {format &&
                format.map(item => (
                  <option value={item.item} key={item.item}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='mb-3 d-none'>
            <label className='form-label text-small'>
              Add Media <FormNeeded />
            </label>
            <FileCreateCX size='media-standard' media={handleMediaBanner} />
            <WebbDividerSmall />
          </div>

          <div className='mb-3'>
            <label className='form-label text-small'>
              Memo <FormNeeded />
            </label>
            <input
              type='text'
              className='form-control height-md'
              style={{ fontSize: '0.9rem', height: '2.7rem' }}
              value={data.memo}
              onChange={({ target }) => {
                handleChange('memo', target.value)
              }}
              disabled
              placeholder=''
            ></input>
          </div>

          <div className='mb-3'>
            <label className='form-label text-small'>
              User Email <FormNeeded />
            </label>
            <input
              className='form-control'
              rows='3'
              style={{ fontSize: '0.9rem' }}
              value={data.user}
              onChange={({ target }) => {
                handleChange('user', target.value)
              }}
              disabled={loader || submit}
            ></input>
          </div>
        </div>

        <div className={submit ? 'd-none' : ''}>
          <WebbDividerMedium />
          <div className='d-flex justify-content-between'>
            <button
              className={`btn btn-light border back-color-wite rounded-xx text-small`}
              type='button'
              disabled={loader || submit}
              onClick={() => {
                navigate(-1)
              }}
            >
              {loader ? 'Please Wait...' : 'Cancel'}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small`}
              type='button'
              disabled={false}
              onClick={() => {
                handleSubmit()
              }}
            >
              {loader ? 'Please Wait...' : 'Submit'}
            </button>
          </div>
        </div>
        <WebbDividerMedium />
        <Toast msg={memo} type={memoType} />
      </div>
    </>
  )
}
