import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm } from "../../services/srvc-utilities";

import { TransfersDataList } from "../../services/srvc-transfers-data-realm";

const list = [
  { status: "0", name: "Created", color: "text-color-tint" },
  { status: "1", name: "In Progress", color: "text-color-wait" },
  { status: "2", name: "In Progress", color: "text-color-wait" },
  { status: "3", name: "******", color: "text-color-wait" },
  { status: "4", name: "Cancelled", color: "text-color-error" },
  { status: "5", name: "******", color: "text-color-wait" },
  { status: "6", name: "Success", color: "text-color-success" },
  { status: "7", name: "Declined (Uer)", color: "text-color-error" },
  { status: "8", name: "Revoked (User)", color: "text-color-error" },
  { status: "9", name: "Cancelled (User)", color: "text-color-error" },
];

export default function TransfersListModule(props) {
  // console.log(props.search)
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const header = ["user", "created", "status", "view"];

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const result = await TransfersDataList({
          data: {
            consumer: asset.item,
          },
        });
        // console.log("result", result);
        if (result.stat) {
          const temp = result.data?.list?.sort((a, b) => b.created - a.created);
          setData(temp);
        }
        setLoader(false);
      };
      fetchData();
    }
  }, []);

  const handleClick = (item) => {
    navigate(`/team/transfers/${item}`);
  };

  if (!loader && (!data || data.length === 0)) return <>No Data</>;

  return (
    <>
      <div className="mt-3 d-md-block d-none">
        <div className="border-bottom"></div>
        {data &&
          data.map((item, x) => (
            <div className="" key={x}>
              <div className="d-flex justify-content-between mt-3 mb-3">
                <div className="me-2">
                  <span
                    className={`align-middle text-small ${
                      list.find((z) => z.status == item.stat).color
                    }`}
                  >
                    <i className="bx bxs-circle"></i>
                  </span>
                </div>

                <div className="" style={{ width: "30%" }}>
                  <p className="text-bold m-0">{item.debt.name || "******"}</p>
                  <p className="m-0 d-none">{item.format.name || "******"}</p>
                  <p className="text-small m-0 d-none">
                    {""}
                    {new Date(item.created).toLocaleString() || "******"}
                  </p>
                </div>

                <div className="" style={{ width: "30%" }}>
                  <p className="text-bold m-0 d-none">
                    {item.debt.name || "******"}
                  </p>
                  <p className="m-0">{item.format.name || "******"}</p>
                  <p className="text-small m-0 d-none">
                    {""}
                    {new Date(item.created).toLocaleString() || "******"}
                  </p>
                </div>

                <div className="" style={{ width: "25%" }}>
                  <p className="text-bold m-0 d-none">
                    {item.debt.name || "******"}
                  </p>
                  <p className="m-0 d-none">{item.format.name || "******"}</p>
                  <p className="m-0">
                    {""}
                    {new Date(item.created).toLocaleString() || "******"}
                  </p>
                </div>

                <div className="ms-auto text-end">
                  <Link to={`/team/transfers/${item.item}`}>View</Link>
                </div>
              </div>
              <div className="border-bottom"></div>
            </div>
          ))}
      </div>

      {/* Mobile-view */}
      <div className="mt-3 d-block d-md-none">
        <div className="border-bottom"></div>
        {data &&
          data.map((item, x) => (
            <div className="" key={x}>
              <div className="d-flex justify-content-between mt-3 mb-3">
                <div className="me-2">
                  <span
                    className={`align-middle text-small ${
                      list.find((z) => z.status == item.stat).color
                    }`}
                  >
                    <i className="bx bxs-circle"></i>
                  </span>
                </div>

                <div className="" style={{ width: "" }}>
                  <p className="text-bold m-0">{item.debt.name || "******"}</p>
                  <p className="m-0 d-none">{item.format.name || "******"}</p>
                  <p className="text-small m-0 d-none">
                    {""}
                    {new Date(item.created).toLocaleString() || "******"}
                  </p>

                  <p className="text-bold m-0 d-none">
                    {item.debt.name || "******"}
                  </p>
                  <p className="m-0">{item.format.name || "******"}</p>
                  <p className="text-small m-0 d-none">
                    {""}
                    {new Date(item.created).toLocaleString() || "******"}
                  </p>

                  <p className="text-bold m-0 d-none">
                    {item.debt.name || "******"}
                  </p>
                  <p className="m-0 d-none">{item.format.name || "******"}</p>
                  <p className="m-0">
                    {""}
                    {new Date(item.created).toLocaleString() || "******"}
                  </p>
                </div>

                <div className="ms-auto text-end mt-3">
                  <Link to={`/team/transfers/${item.item}`}>View</Link>
                </div>
              </div>
              <div className="border-bottom"></div>
            </div>
          ))}
      </div>
    </>
  );
}
