// assets
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import FileCreateCX from "../../services/srvc-filecreate-cweb-xx";
import { AssetCreate } from "../../services/srvc-assets-realm";

const listFormat = [
  {format: 'academics', sort: ['certificate']},
  {format: 'work', sort: ['employee', 'contractor', 'intern']},
  {format: 'social', sort: ['membership', 'event']}
]


export default function AssetsCreateModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [memo, setMemo] = useState('');

  const [format, setFormat] = useState()
  const [sort, setSort] = useState()

  const [media, setMedia] = useState({})
  const [data, setData] = useState({
    name: '', memo: '',
    format: '', sort: '',
    start: '', end: ''
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        setFormat(Array.from(listFormat, x => { return {name: x.format}}))
        
        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])        

  useEffect( () => {
    if (data.format !== '') {
      setSort(null)
      handleChange('sort', '')
      const sortx = listFormat.find(x => x.format == data.format).sort
      setSort(Array.from(sortx, z => { return {name: z}}))
    } else {
      setSort(null)
      handleChange('sort', '')
    }
     

  },[data.format]);

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' && data.memo!=='' && media.link!=="") setForm(true);

  },[data]);

  const handleSubmit = async () => {

    setLoader(true);
    setSubmit(true);
    setMemo('Please wait...')
    
    const datx = {
      "name": data.name, 
      "memo": data.memo,
      "media": media,
      "dates": { "start": data.start, "end": data.end },
      "feature": {"format": data.format, "sort": data.sort, "role": "user"},
      "units": { "number":"", "ticker": "" },
      "rate": { "number":"", "ticker": "" },
      "creator": asset.item      
    }
    // console.log (datx)

    const result = await AssetCreate({data: datx, srvc: '******'})
    // console.log (result)
    
    if (result.stat) {
      setDone(true)
      setMemo('Asset Created')
    } else {
      setDone(false)
      setMemo('Asset Creation Failed')
    }

    setLoader(false)
    
  }

  const handleMediaBanner = async(filedata) => {
    // console.log (filedata);
    setMedia({link: filedata?.file.link, mime: filedata?.file?.mime});
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }



  // if (loader) return <><div className="text-center"><WebbLoaderSmall/></div></>


  return (
  <>
    {/* info */}
    <div className="mx-3">
      <h2 className='text-normal m-0'>{'Create Asset'}</h2>
      
      <WebbDividerSmall/>
    </div>


    <div className={submit ? 'back-color-wite p-3 rounded-xd' : 'back-color-wite p-3 rounded-xd'}>

      <div className={''}>
        <p className="text-normal m-0">1. Select Format</p>
        <WebbDividerSmall/>

        <div className="mb-3">
          <label className="form-label text-small mb-3">Select Asset Format <FormNeeded/></label>
          <div className="mt-2 mb-2">
            {format && format.map((item, i) => (
              <span className={`p-3 px-4 rounded-wd me-1 cursor 
                ${data.format == item.name ? 'back-color-main text-color-wite' : 'back-color-lite'}`} key={i}
                onClick={() => handleChange('format', item.name)}
                >
                {item.name}
              </span>
            ))}
          </div>

          <WebbDividerMedium />
          <div className="">
            {sort && sort.map((item, i) => (
              <span className={`p-3 px-4 rounded-wd me-1 cursor 
                ${data.sort == item.name ? 'back-color-next text-color-wite' : 'border'}`} key={i}
                onClick={() => handleChange('sort', item.name)}
                >
                {item.name}
              </span>
            ))}
          </div>          

        </div>
      </div>

      <WebbDividerMedium />

      <div className={data.format == "" || data.sort == "" ? 'd-none' : ''}>

        <p className="text-normal m-0">2. Enter Details</p>
        <WebbDividerSmall/>

        <div className="mb-3">
          <label className="form-label text-small">Add Media <FormNeeded/></label>
          <FileCreateCX size='media-standard' media={handleMediaBanner} />
          
        </div>

        <WebbDividerSmall/>
        <div className="mb-3">  
          <label className="form-label text-small">Name <FormNeeded/></label>
          <input type="text" className="form-control height-md"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.name}
            onChange={({ target }) => {handleChange("name", target.value); }}
            disabled={loader || submit}
            placeholder="">
          </input>
        </div>

        <div className="mb-3">  
          <label className="form-label text-small">Details <FormNeeded/></label>
          <textarea 
            className="form-control" rows="6"
            style={{fontSize:'0.9rem'}}
            value={data.memo}
            onChange={({ target }) => {handleChange("memo", target.value); }}
            disabled={loader || submit}
          ></textarea>
        </div>

        <div className="d-flex mb-3">
          <div className="w-50 me-3">
            <label className="form-label text-small">Start Date <FormNeeded/></label>
            <input type="date" className="form-control height-md"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.start}
              onChange={({ target }) => {handleChange("start", target.value); }}
              disabled={loader || submit}
              placeholder="">
            </input>
          </div>
          <div className="w-50">
            <label className="form-label text-small">End Date <FormNeeded/></label>
            <input type="date" className="form-control height-md"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.end}
              onChange={({ target }) => {handleChange("end", target.value); }}
              disabled={loader || submit}
              placeholder="">
            </input>
          </div>
        </div>

      </div>

      <WebbDividerMedium />
      <div className="">
        <p className="m-0">{memo}</p>
      </div>

      <div className={submit ? 'd-none' : ''}>
        <WebbDividerMedium />
        <div className="d-flex justify-content-between">

        <button className={`btn btn-light border back-color-wite rounded-xx text-small`}
          type="button"
          disabled={loader || submit}
          onClick={()=> { navigate(-1)}}
        >{loader ? 'Please Wait...' : 'Cancel'}</button>

        <button className={`btn btn-primary border-none rounded-xx text-small`}
          type="button"
          disabled={!form || loader || submit}
          onClick={()=> { handleSubmit()}}
        >{loader ? 'Please Wait...' : 'Continue'}</button>

        </div>


      </div>

      <WebbDividerSmall />
    </div>


  </>

  )
}