import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { TransfersDataDetails } from "../../services/srvc-transfers-data-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { DispalyLoder, FormDisplay, STATUS_CODES } from "../common/utils";
import WebbDividerSmall from "../webx/webb-divider-sm";
import CredCards from "./CredCards";

export default function TransfersViewConsumerModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [memo, setMemo] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const res = await TransfersDataDetails({
          data: {
            item: id,
            consumer: asset.item,
          },
        });
        // console.log(res);

        if (res?.stat) {
          setData(res.data.vars);
          const status = STATUS_CODES.find(
            (status) => status.code === res.data.stat
          );
          setMemo(`Transaction ${status.memo}`);
          props.handleData(res.data);
        }
        setLoader(false);
      };
      fetchData();
    }
  }, []);
  if (loader) return <>Please Wait...</>;
  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={memo || "No Transfers Listed"} />;

  return (
    <>
      <div className={"back-color-wite"}>
        {data &&
          data.map((item, x) =>
            item.name !== "" ? (
              <div key={x}>
                <p className="m-0">{item.name}</p>
                <p className="text-bold m-0">{item?.data?.value || "******"}</p>
                {Array.isArray(item.data.file) ? (
                  item.data.file.map((file) => (
                    <>
                      <a
                        className={file ? "" : "d-none"}
                        href={file || ""}
                        target="_blank"
                      >
                        {file ? "View / Download File" : ""}
                      </a>
                      <br />
                    </>
                  ))
                ) : (
                  <a
                    className={item?.data?.file ? "" : "d-none"}
                    href={item?.data?.file || ""}
                    target="_blank"
                  >
                    {item?.data?.file ? "View / Download File" : ""}
                  </a>
                )}
                <p></p>
              </div>
            ) : (
              ""
            )
          )}
      </div>
    </>
  );
}
