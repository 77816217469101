// auth - firebase mail link
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { TransfersDataStatistics } from "../../services/srvc-transfers-datax-realm";

const list = [
  {name: 'Total', code: 'total', icon: 'bx bx-right-arrow-circle', number: '', ticker: '***', rate: '', active: true},
  {name: 'Success', code: 'success', icon: 'bx bx-check-circle', number: '', ticker: '***', rate: '', active: true},
  {name: 'Due/Failed', code: 'others', icon: 'bx bx-error-circle', number: '', ticker: '***', rate: '', active: true},
]

export default function TeamTransferStatisticsModule () {

  const navigate = useNavigate();
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState()

  // console.log(listactions.filter(x => x.user.includes(asset.role)))

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const result = await TransfersDataStatistics({data: {user: asset.item}, srvc: '******'})
        // console.log (result)
        // setData(result)
        
        const stats = Array.from(list, x =>{ return {
          ...x, 
          number: result?.data[x?.code]  || '0',
          rate: (Math.random()*10).toFixed(0)
        }})
        setData(stats)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  }

  if (loader) return <></>


  return (
  <>
    
    <div className="">

      <div className="row row-cols-1 row-cols-md-3 g-2">
        {data && data.map((item, i) => (item.active ?
        
        <div className="col" key={i}>

          <div className={`back-color-wite rounded p-3 w-100 h-100 border m-0 p-0`}>
            
            <div className="d-flex">
              <div className="">
                <p className="text-lead text-bold m-0">{item.number || '0'}</p>
                <p className="text-small m-0">{item.name}</p>
              </div>
              <div className="ms-auto ">
                <i className={`text-icon-md text-color-tint ${item.icon}`}></i>
              </div>
            </div>
            
            <div className="mb-2 d-none"></div>

            <p className="m-0 d-none">
              <span><i className="bx bx-up-arrow-alt text-color-success"></i></span>
              <span>{item.rate || '0'}{'%'}</span>
            </p>

          </div>

        </div>
        :''))}

      </div>
    </div>
  </>

  )
}